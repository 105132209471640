import { useEffect, useState } from "react"
import useFetch, { FetchState } from "./useFetch"

/**
 * Shipment Tracking API response format
 */
export type ShipmentTrackingDataItem = {
  id: number // 54357,
  order_no: string // "A116672",
  position: string // "100",
  referral?: string // null,
  article_code: string // "AC3000",
  article_name: string // "Kiuaskivet 20 kg<10cm 8260795",
  amount_ordered: string // "2",
  amount_delivered: string // "0",
  unit: string // "kpl",
  delivery_date: string // "26-08-21",
  shipment_date: string // "26-08-21",
  language: string // "FI",
  status: string // "Avoin",
  backorder?: string // null,
  created_at: string // "2021-08-26T08:01:53.000000Z",
  updated_at: string // "2021-08-26T08:01:53.000000Z",
}

function useShipmentTrackinApi(): [FetchState<ShipmentTrackingDataItem[]>, (orderNo: string) => void] {
  const [state, makeRequest] = useFetch<ShipmentTrackingDataItem[]>()
  const [orderNo, setOrderNo] = useState<string>()

  useEffect(() => {
    if (!orderNo) {
      return
    }
    // console.debug("useShipmentTrackinApi calling makeRequest", orderNo)
    makeRequest(`https://shipment-tracking.harvia.fi/api/order/${orderNo}`)
  }, [orderNo, makeRequest])

  return [
    state,
    (newOrderNo: string) => {
      if (newOrderNo === orderNo) {
        return
      }
      setOrderNo(newOrderNo)
    },
  ]
}

export default useShipmentTrackinApi
