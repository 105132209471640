import React, { ReactNode, useContext, useEffect, useRef, useState } from "react"
import { PageContext } from "../../../context/PageContext"
import useDatoMicrocopy from "../../../hooks/useDatoMicrocopy"
import useShipmentTrackinApi from "../../../hooks/useShipmentTrackingApi"
import Button from "../../atoms/Button"
import Input from "../../atoms/form/Input"
import * as styles from "./ShipmentTrackingForm.module.scss"
import ShipmentTrackingFormResult from "./subcomponents/ShipmentTrackingFormResult"

export type ShipmentTrackingFormProps = {
  title?: ReactNode
  /** Help text or like content from Dato */
  description?: ReactNode
  /** Content (from Dato) shown after successful submission */
  successMessage?: ReactNode
  /** Generic "Something went terribly wrong" message from Dato  */
  errorMessage?: ReactNode
  /** Do we try to excecute reCAPTCHA during form submission? Falsy value implies no reCAPTCHA */
  recaptchaAction?: string
}

type UiState = "initial" | "success" | "loading" | "error"

const ShipmentTrackingForm = (props: ShipmentTrackingFormProps) => {
  // console.debug("Rendering ShipmentTrackingForm", props)
  const containerRef = useRef<HTMLDivElement>(null) // so that we can scroll this into view after uiState change if needed
  const formRef = useRef<HTMLFormElement>(null)
  const [orderNo, setOrderNo] = useState<string>()
  const [state, makeRequest] = useShipmentTrackinApi()
  const [uiState, setUiState] = useState<UiState>("initial")
  const { locale } = useContext(PageContext)
  const t = useDatoMicrocopy("shipmentTracking", locale)

  // console.debug(`ShipmentTrackingForm UI state "${uiState}", fetch state`, state)

  // console.debug("transl", transl)

  useEffect(() => {
    switch (state.status) {
      case "fetching":
        setUiState("loading")
        break
      case "error":
        setUiState("error")
        break
      case "fetched":
        setUiState("success")
        break
    }
  }, [state])

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (formRef.current === null) {
      return
    }

    if (!orderNo) {
      setUiState("error")
      return
    }
    makeRequest(orderNo)
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (uiState === "error") {
      setUiState("initial")
    }

    const newOrderNo = e.currentTarget.value
    if (newOrderNo !== orderNo) {
      setUiState("initial")
      setOrderNo(newOrderNo)
    }
  }

  // loading={state.loading}
  // errors={state.error?.fieldErrors}
  const isDisabled =
    uiState === "loading" || uiState === "error" || !orderNo || (state.status === "fetched" && uiState === "success")

  return (
    <div /* className={styles[uiState]}*/ ref={containerRef}>
      {props.title}
      <div className="description">{props.description}</div>
      <div className="form">
        <form ref={formRef} onSubmit={onSubmit} name="contact-b2b" className={styles.form}>
          <Input
            name="orderNo"
            label={t("orderNumber.label", "Order number")}
            required={true}
            placeholder="A123456"
            errorMsg=""
            inputAttrs={{ onChange: onInputChange }}
          />
          <Button label={t("checkStatus.buttonLabel", "Check status")} type="submit" disabled={isDisabled} />
        </form>
      </div>
      {uiState === "error" && <div className="error">{props.errorMessage}</div>}
      {uiState !== "error" && (
        <div className={styles.results}>
          <h3>{t("result.title", "Order status")}</h3>
          {state.data && <ShipmentTrackingFormResult items={state.data} />}
        </div>
      )}
    </div>
  )
}

export default ShipmentTrackingForm
