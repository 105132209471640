import { useEffect, useReducer, useRef, useState } from "react"

export type FetchState<T> = {
  status: "idle" | "fetching" | "error" | "fetched"
  data?: T
  error?: Error
}

// storage for response data
type Cache<T> = { [url: string]: T }

// discriminated union type
type Action<T> = { type: "FETCHING" } | { type: "FETCHED"; payload: T } | { type: "ERROR"; payload: Error }

/**
 * Generic hook to retrieve data on an API using the native Fetch API.
 * Exports a tuple of `[state, makeRequest]`. Configuration for native `fetch()` can be passed to hook as parameter.
 * - `state` is an object with `status`, `data` and `error` as properties.
 * - `makeRequest` is a function that takes an URL and triggers the fetch.
 *
 * @param {RequestInit} options Fetch API config object.
 * @returns A tuple of `[state, makeRequest]`
 */
function useFetch<T = unknown>(options?: RequestInit): [FetchState<T>, (url: string) => void] {
  const cache = useRef<Cache<T>>({})
  const [url, setUrl] = useState<string>()

  // Used to prevent state update if the component is unmounted
  const cancelRequest = useRef<boolean>(false)

  const initialState: FetchState<T> = {
    status: "idle",
    error: undefined,
    data: undefined,
  }

  // Keep state logic separated
  const fetchReducer = (state: FetchState<T>, action: Action<T>): FetchState<T> => {
    // console.debug("useFetch reducer", state, action)
    switch (action.type) {
      case "FETCHING":
        return { ...initialState, status: "fetching" }
      case "FETCHED":
        return { ...initialState, status: "fetched", data: action.payload }
      case "ERROR":
        return { ...initialState, status: "error", error: action.payload }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(fetchReducer, initialState)

  useEffect(() => {
    // Do nothing if the url is not given
    if (!url) return

    // Used to prevent state update if the component is unmounted
    cancelRequest.current = false

    const fetchData = async () => {
      dispatch({ type: "FETCHING" })

      // If a cache exists for this url, return it
      if (cache.current[url]) {
        // console.debug("useFetch returning from cache", cache.current[url])
        dispatch({ type: "FETCHED", payload: cache.current[url] })
        return
      }

      try {
        const response = await fetch(url, options)
        if (!response.ok) {
          throw new Error(response.statusText)
        }

        const data = (await response.json()) as T
        cache.current[url] = data
        if (cancelRequest.current) {
          // console.debug("useFetch canceled", state, data)
          return
        }
        dispatch({ type: "FETCHED", payload: data })
      } catch (error) {
        console.warn("useFetch fetch error", error)
        if (cancelRequest.current) {
          return
        }
        dispatch({ type: "ERROR", payload: error as Error })
      }
    }

    fetchData()

    // Use the cleanup function for avoiding a possibly...
    // ...state update after the component was unmounted
    return () => {
      cancelRequest.current = true
    }
  }, [url, options, dispatch])

  const makeRequest = (newUrl: string) => {
    // console.debug("useFetch makeRequest called", newUrl)
    if (!newUrl) {
      return
    }
    setUrl(newUrl)
  }

  return [state, makeRequest]
}

export default useFetch
