import React, { useContext } from "react"
import { PageContext } from "../../../../context/PageContext"
import useDatoMicrocopy from "../../../../hooks/useDatoMicrocopy"
import { ShipmentTrackingDataItem } from "../../../../hooks/useShipmentTrackingApi"
// import * as styles from "./ShipmentTrackingFormResult.module.scss"

export type ShipmentTrackingFormResultProps = {
  items: ShipmentTrackingDataItem[]
}

const DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  timeZone: "UTC",
}

/**
 * Convert a date from `DD-MM-YY` format, as used in shipment-tracking.harvia.fi API
 * to, a localized string.
 *
 * @param locale A locale string or array of locale strings that contain one or more
 * language or locale tags. If you include more than one locale string, list them in
 * descending order of priority so that the first entry is the preferred locale.
 * If you omit this parameter, the default locale of the JavaScript runtime is used.
 * @param input String representation of a date in `DD-MM-YY` format, e.g. "15-09-21"
 * @returns String representation of the same date in users locale, e.g. "15.9.2021"
 */
function formatDate(input?: string, locale?: string | string[]) {
  if (!input) {
    return ""
  }
  let date: Date | null = null
  try {
    const [dd, mm, yy] = input.split(/-|\//).map(v => parseInt(v, 10))
    date = new Date(Date.UTC(2000 + yy, mm - 1, dd))
  } catch (e) {
    return ""
  }
  return date.toLocaleDateString(locale, DATE_OPTIONS)
}

const Row = (props: { locale: string; status: string; unit: string; item: ShipmentTrackingDataItem }) => {
  const { item, status, unit, locale } = props
  return (
    <tr>
      <td>{status}</td>
      <td>{item.position}</td>
      <td>{item.referral}</td>
      <td>
        {item.amount_ordered} {unit}
      </td>
      <td>
        {item.amount_delivered} {unit}
      </td>

      <td>{formatDate(item.shipment_date, locale)}</td>
      {/* <td>{formatDate(item.shipment_date, locale)}</td> */}
      <td>{formatDate(item.backorder, locale)}</td>
    </tr>
  )
}

const ShipmentTrackingFormResult = (props: ShipmentTrackingFormResultProps) => {
  // console.debug("Rendering ShipmentTrackingFormResult", props)
  const { locale } = useContext(PageContext)
  const t = useDatoMicrocopy("shipmentTracking", locale)

  return (
    <table>
      <thead>
        <tr>
          <td>{t("status", "Status")}</td>
          <td>{t("positionNumber", "Position")}</td>
          <td>{t("referral", "Referral")}</td>
          <td>{t("ordered", "Ordered")}</td>
          <td>{t("sentPcs", "Sent")}</td>
          <td>{t("deliveryDate", "Delivery")}</td>
          {/* <td>{t("sendDate", "Send")}</td> */}
          <td>{t("backorder", "Backorder")}</td>
        </tr>
      </thead>
      <tbody>
        {props.items.map(item => (
          <Row
            key={item.id}
            item={item}
            locale={locale}
            status={t(`status.${item.status}`, item.status)}
            unit={t(`unit.${item.unit}`, item.unit)}
          />
        ))}
      </tbody>
    </table>
  )
}

export default ShipmentTrackingFormResult
